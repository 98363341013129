import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

import { mostrarMensagem } from "../../components/mensagem/mensagem";

/* Types & Action Creators */
const { Types, Creators } = createActions({
  integracaoSagahDadosCadastroRequest: null,
  integracaoSagahDadosCadastroSuccess: ["dataCadastro"],
  integracaoSagahDadosNotaRequest: null,
  integracaoSagahDadosNotaSuccess: ["dataNota"],
  integracaoSagahLerPeriodoLetivoRequest: null,
  integracaoSagahLerPeriodoLetivoSuccess: ["dataPeriodoLetivo"],
  integracaoSagahFailure: ["erro"],
  integracaoSagahAtualizarDatasRequest: ["parametro"],
  integracaoSagahAtualizarDatasSuccess: null
});

export const IntegracaoSagahTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  loading: false,
  dataCadastro: [],
  dataNota: [],
  dataPeriodoLetivo: []
});

/* Reducers */

export const integracaoSagahDadosCadastroRequestReducer = state => {
  mostrarMensagem("warning", "Por favor, aguarde o processamento", "1", 0);
  return state.merge({
    loading: true
  });
};

export const integracaoSagahDadosCadastroSuccessReducer = (
  state,
  { dataCadastro }
) => {
  mostrarMensagem(
    "success",
    "Processamento efetuado com sucesso.",
    "warning",
    2000
  );
  return state.merge({
    loading: false,
    dataCadastro
  });
};

export const integracaoSagahDadosNotaRequestReducer = state => {
  mostrarMensagem("warning", "Por favor, aguarde o processamento", "1", 0);
  return state.merge({
    loading: true
  });
};

export const integracaoSagahDadosNotaSuccessReducer = (state, { dataNota }) => {
  mostrarMensagem(
    "success",
    "Processamento efetuado com sucesso.",
    "warning",
    2000
  );
  return state.merge({
    loading: false,
    dataNota
  });
};

export const integracaoSagahLerPeriodoLetivoRequestReducer = state => {
  mostrarMensagem("warning", "Por favor, aguarde o processamento", "1", 0);

  return state.merge({
    loading: true
  });
};

export const integracaoSagahLerPeriodoLetivoSuccessReducer = (
  state,
  { dataPeriodoLetivo }
) => {
  mostrarMensagem(
    "success",
    "Processamento efetuado com sucesso.",
    "warning",
    2000
  );

  return state.merge({
    loading: false,
    dataPeriodoLetivo
  });
};

export const integracaoSagahFailureReducer = (state, { erro }) => {
  mostrarMensagem("error", erro, "warning", 6000);

  return state.merge({
    loading: false
  });
};

export const integracaoSagahAtualizarDatasRequestReducer = (
  state,
  { parametro }
) => {
  mostrarMensagem("warning", "Por favor, aguarde o processamento", "1", 0);
  return state.merge({
    loading: true
  });
};

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.INTEGRACAO_SAGAH_DADOS_CADASTRO_REQUEST]: integracaoSagahDadosCadastroRequestReducer,
  [Types.INTEGRACAO_SAGAH_DADOS_CADASTRO_SUCCESS]: integracaoSagahDadosCadastroSuccessReducer,
  [Types.INTEGRACAO_SAGAH_DADOS_NOTA_REQUEST]: integracaoSagahDadosNotaRequestReducer,
  [Types.INTEGRACAO_SAGAH_DADOS_NOTA_SUCCESS]: integracaoSagahDadosNotaSuccessReducer,
  [Types.INTEGRACAO_SAGAH_LER_PERIODO_LETIVO_REQUEST]: integracaoSagahLerPeriodoLetivoRequestReducer,
  [Types.INTEGRACAO_SAGAH_LER_PERIODO_LETIVO_SUCCESS]: integracaoSagahLerPeriodoLetivoSuccessReducer,
  [Types.INTEGRACAO_SAGAH_FAILURE]: integracaoSagahFailureReducer,
  [Types.INTEGRACAO_SAGAH_ATUALIZAR_DATAS_REQUEST]: integracaoSagahAtualizarDatasRequestReducer
});
