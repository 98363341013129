import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import ContentHeader from "../../components/formulario/cabecalho/contentHeader";
import Content from "../../components/formulario/cabecalho/content";
import Container from "../../components/container/container";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

function IntegracaoEnvioDadosCadastro() {
  const { dataCadastro } = useSelector(state => state.integracaoSagah);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "INTEGRACAO_SAGAH_DADOS_CADASTRO_REQUEST" });
  }, []);

  var options = {
    noDataText: "Não existem dados de processamento do envio do log"
  };

  return (
    <React.Fragment>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <Container>
              <ContentHeader title="Log Envio de Dados Cadastrais" />
              <div className="box-body">
                <BootstrapTable
                  data={dataCadastro}
                  pagination={false}
                  search={false}
                  striped={false}
                  options={options}
                >
                  <TableHeaderColumn
                    dataField="descricaoTipoEnvioLog"
                    isKey={true}
                    dataSort={true}
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                  >
                    Tipo de Envio
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="dataHoraEnvioInicio"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                    dataSort={true}
                  >
                    Data/Hora Início
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="dataHoraEnvioFim"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                    dataSort={true}
                  >
                    Data/Hora Fim
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="descricaoSituacaoEnvioLog"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                    dataSort={true}
                  >
                    Situação
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="mensagemComplementar"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="40%"
                  >
                    Mensagem
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default IntegracaoEnvioDadosCadastro;
