import React from "react";

import { ConnectedRouter } from "connected-react-router";

import { Switch } from "react-router-dom";

import history from "./history";
import Private from "./private";
import Guest from "./guest";

import Main from "../pages/main";
import App from "../pages/app";
import Dashborad from "../pages/dashboard";
import IntegracaoEnvioDadosCadastro from "../pages/integracaoEnvioDadosCadastro";
import IntegracaoEnvioNotas from "../pages/integracaoEnvioNotas";
import ParametroEnvio from "../pages/parametroEnvio";
import Error from "../pages/error";

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Guest path="/" exact component={Main} />
      <Private path="/app" exact component={App} />
      <Private path="/app/dashboard" exact component={Dashborad} />
      <Private
        path="/app/integracaoEnvioDadosCadastro"
        exact
        component={IntegracaoEnvioDadosCadastro}
      />
      <Private
        path="/app/integracaoEnvioNotas"
        exact
        component={IntegracaoEnvioNotas}
      />
      <Private path="/app/parametroEnvio" exact component={ParametroEnvio} />
      <Guest path="/error/:id" component={Error} />
    </Switch>
  </ConnectedRouter>
);

export default Routes;
