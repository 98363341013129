import React from "react";

import ContentHeader from "../../components/formulario/cabecalho/contentHeader";
import Content from "../../components/formulario/cabecalho/content";

export default function error(props) {
  var urlOriginal = props.location.pathname.split("/");
  var descricaoErro = "Erro de autenticação";
  if (urlOriginal[urlOriginal.length - 1] === "1") {
    descricaoErro = "Usuário não autenticado";
  }

  return (
    <div>
      <ContentHeader title="Erro" small="" />
      <Content>
        <h1>{descricaoErro}</h1>
      </Content>
    </div>
  );
}
