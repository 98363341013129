import { call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrActions } from "react-redux-toastr";
import api from "../../services/apiGetTokenPublic";
import apiComTokenPublico from "../../services/apiPublicWSComTokenPublico";
import AuthActions from "../ducks/auth";

export function* recuperarTokenPublico({ tokenPortal }) {
  try {
    const { data } = yield call(
      api.get,
      "/br.edu.unipam.unseg.api.Usuario/api/TokenPublico/Get"
    );

    if (data.Completed === "success") {
      window.sessionStorage.setItem("@intSaga:tokenPublico", data.Result);
      yield put(AuthActions.tokenPublicoSuccess(data.Result, tokenPortal));
      yield call(autenticarTokenPortal);
    } else {
      //yield put(push("www.unipam.edu.br"));
    }
  } catch (err) {
    yield put(AuthActions.signInError());
    yield put(
      toastrActions.add({
        type: "error",
        title: "Falha no login",
        message: "Verifique seu e-mail/senha"
      })
    );
  }
}

export function* autenticarTokenPortal() {
  try {
    //const { tokenPortal, tokenPublico } = yield select(state => state.auth);
    const tokenPortal = window.sessionStorage.getItem("@intSaga:tokenPortal");

    var url =
      "/br.edu.unipam.unseg.api.Conexao/api/ControleAcesso/ControleAcessoSistema/" +
      tokenPortal;
    if (process.env.NODE_ENV === "development")
      url =
        "/br.edu.unipam.unseg.api.Conexao/api/ControleAcesso/ControleAcessoSistemaTeste/" +
        tokenPortal;

    const { data } = yield call(apiComTokenPublico.get, url);

    if (data.Completed === "success") {
      yield put(AuthActions.signInSuccess(data));
      yield put(push("/app"));
    } else {
      console.log("ERRO success");
      console.log(data);
    }
  } catch (err) {
    yield put(AuthActions.signInError());
    yield put(
      toastrActions.add({
        type: "error",
        title: "Falha no login",
        message: "Verifique seu e-mail/senha"
      })
    );
  }
}
