import { call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrActions } from "react-redux-toastr";
import api from "../../services/apiPublicWSComAccessToken";
import IntegracaoSagahActions from "../ducks/integracaoSagah";
import ModalActions from "../ducks/modal";

export function* lerLogEnvioDadosCadastro() {
  try {
    const { data } = yield call(
      api.post,
      "/br.edu.unipam.RM.SAGAH.Integracao/api/LogCadastro/ListarLogDadosCadastrais"
    );

    if (data.completed === "success") {
      yield put(
        IntegracaoSagahActions.integracaoSagahDadosCadastroSuccess(data.result)
      );
    } else {
      yield put(
        IntegracaoSagahActions.integracaoSagahFailure(data.messages[0].text)
      );
    }
  } catch (err) {
    yield put(IntegracaoSagahActions.integracaoSagahFailure(err.message));
  }
}

export function* lerLogEnvioDadosNota() {
  try {
    const { data } = yield call(
      api.post,
      "/br.edu.unipam.RM.SAGAH.Integracao/api/LogCadastro/ListarLogNotas"
    );

    if (data.completed === "success") {
      yield put(
        IntegracaoSagahActions.integracaoSagahDadosNotaSuccess(data.result)
      );
    } else {
      yield put(
        IntegracaoSagahActions.integracaoSagahFailure(data.messages[0].text)
      );
    }
  } catch (err) {
    yield put(IntegracaoSagahActions.integracaoSagahFailure(err.message));
  }
}

export function* lerPeriodoLetivo() {
  try {
    const { data } = yield call(
      api.post,
      "/br.edu.unipam.RM.SAGAH.Integracao/api/PeriodoLetivo/ListarPeriodoLetivo"
    );

    if (data.completed === "success") {
      yield put(
        IntegracaoSagahActions.integracaoSagahLerPeriodoLetivoSuccess(
          data.result
        )
      );
    } else {
      yield put(
        IntegracaoSagahActions.integracaoSagahFailure(data.messages[0].text)
      );
    }
  } catch (err) {
    yield put(IntegracaoSagahActions.integracaoSagahFailure(err.message));
  }
}

export function* atualizarData(action) {
  try {
    const { data } = yield call(
      api.post,
      "/br.edu.unipam.RM.SAGAH.Integracao/api/PeriodoLetivo/AtualizarDatas",
      action.parametro
    );

    if (data.completed === "success") {
      yield put(
        IntegracaoSagahActions.integracaoSagahLerPeriodoLetivoRequest()
      );
      yield put(ModalActions.modalClose());
    } else {
      yield put(
        IntegracaoSagahActions.integracaoSagahFailure(data.messages[0].text)
      );
    }
  } catch (err) {
    yield put(IntegracaoSagahActions.integracaoSagahFailure(err.message));
  }
}
