import React, { useState } from "react";
import Tooltip from "rc-tooltip";
import placements from "rc-tooltip/lib/placements";
import "rc-tooltip/assets/bootstrap.css";

function ToolTipText({ texto, children, height, width }) {
  const [destroyTooltipOnHide, setDestroyTooltipOnHide] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [trigger, setTrigger] = useState({
    hover: 1
  });
  const [offsetX, setOffsetX] = useState(placements.right.offset[0]);
  const [offsetY, setOffsetY] = useState(placements.right.offset[1]);
  const [transitionName, setTransitionName] = useState("");

  function onPlacementChange(e) {
    const placement = e.target.value;
    const offset = placements[placement].offset;
    setPlacement(e.target.value);
    setOffsetX(offset[0]);
    setOffsetY(offset[1]);
  }
  function onTransitionChange(e) {
    setTransitionName(e.target.checked ? e.target.value : "");
  }
  function onTriggerChange(e) {
    if (e.target.checked) {
      setTrigger({ hover: 1 });
    } else {
      setTrigger({});
    }
  }
  function onOffsetXChange(e) {
    const targetValue = e.target.value;

    setOffsetX(targetValue || undefined);
  }
  function onOffsetYChange(e) {
    const targetValue = e.target.value;
    setOffsetY(targetValue || undefined);
  }
  function onVisibleChange(visible) {
    //  console.log("tooltip", visible); // eslint-disable-line no-console
  }
  function onDestroyCheck() {
    setDestroyTooltipOnHide(!destroyTooltipOnHide);
  }

  return (
    <Tooltip
      placement={placement}
      mouseEnterDelay={0}
      mouseLeaveDelay={0.1}
      destroyTooltipOnHide={destroyTooltipOnHide}
      trigger={Object.keys(trigger)}
      onVisibleChange={onVisibleChange}
      overlay={<div style={{ height: "auto", width: "auto" }}>{texto}</div>}
      align={{
        offset: [offsetX, offsetY]
      }}
      transitionName={transitionName}
    >
      {children}
    </Tooltip>
  );
}

export default ToolTipText;
