import axios from "axios";
import store from "../store";
//
const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost"
      : "https://publicws.unipam.edu.br"
});

api.interceptors.request.use(config => {
  const accessToken = window.sessionStorage.getItem("@intSaga:accessToken");
  // const { active: team } = store.getState().teams;

  const headers = {
    ...config.headers
  };
  headers.Authorization = `Bearer ${accessToken}`;
  return {
    ...config,
    headers
  };
});

export default api;
