import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import CustomModal from "../../components/CustomModal";
import DataHoraLabel from "../../components/formulario/dataHoraLabel/dataHoraLabel.jsx";

import { mostrarMensagem } from "../../components/mensagem/mensagem";

function DatasControle() {
  const { modalData } = useSelector(state => state.modal);

  const [idPerLet, setIdPerLet] = useState(modalData.idPerLet);

  const [
    dataInicioEnvioDadosMatriculaAvalia,
    setDataInicioEnvioDadosMatriculaAvalia
  ] = useState(modalData.dataInicioEnvioDadosMatriculaAvalia);

  const [
    dataFimEnvioDadosMatriculaAvalia,
    setDataFimEnvioDadosMatriculaAvalia
  ] = useState(modalData.dataFimEnvioDadosMatriculaAvalia);

  const [dataInicioEnvioNotaAvalia, setDataInicioEnvioNotaAvalia] = useState(
    modalData.dataInicioEnvioNotaAvalia
  );

  const [dataFimEnvioNotaAvalia, setDataFimEnvioNotaAvalia] = useState(
    modalData.dataFimEnvioNotaAvalia
  );

  /* Ao iniciar */
  useEffect(() => {}, []);

  function onChangeDataInicioEnvioDadosMatriculaAvalia(data) {
    setDataInicioEnvioDadosMatriculaAvalia(data);
  }
  function onChangeDatasFimEnvioDadosMatriculaAvalia(data) {
    setDataFimEnvioDadosMatriculaAvalia(data);
  }
  function onChangeDataInicioEnvioNotaAvalia(data) {
    setDataInicioEnvioNotaAvalia(data);
  }
  function onChangeDataFimEnvioNotaAvalia(data) {
    setDataFimEnvioNotaAvalia(data);
  }

  const dispatch = useDispatch();

  function closeModalFunction() {
    dispatch({ type: "MODAL_TYPE", modalType: "", modalData: null });
  }

  function actionModalFunction() {
    //var todayDate = new Date().toISOString().slice(0, 10);
    //alert(todayDate);

    var dataInicioData = "";
    var dataFimData = "";
    var dataInicioNota = "";
    var dataFimNota = "";

    // INICIO: Verificar o preenchimento das datas início e fim do envio dos
    // dados dos alunos para o sistema avalia.
    if (
      dataInicioEnvioDadosMatriculaAvalia === "" ||
      dataInicioEnvioDadosMatriculaAvalia === null
    ) {
      if (
        dataFimEnvioDadosMatriculaAvalia !== "" &&
        dataFimEnvioDadosMatriculaAvalia !== null
      ) {
        mostrarMensagem(
          "error",
          "Data início do envio de dados do avalia não informado",
          "warning",
          6000
        );
        document.getElementById("dataInicioEnvioDadosMatriculaAvalia").focus();
        return;
      }
    } else {
      if (
        dataFimEnvioDadosMatriculaAvalia === "" ||
        dataFimEnvioDadosMatriculaAvalia === null
      ) {
        mostrarMensagem(
          "error",
          "Data fim do envio de dados do avalia não informado",
          "warning",
          6000
        );
        document.getElementById("dataFimEnvioDadosMatriculaAvalia").focus();
        return;
      }
      if (
        dataFimEnvioDadosMatriculaAvalia < dataInicioEnvioDadosMatriculaAvalia
      ) {
        mostrarMensagem(
          "error",
          "Data início do envio de dados do avalia maior que a data fim do envio",
          "warning",
          6000
        );
        document.getElementById("dataInicioEnvioDadosMatriculaAvalia").focus();
        return;
      }

      if (typeof dataInicioEnvioDadosMatriculaAvalia !== "object") {
        dataInicioData =
          dataInicioEnvioDadosMatriculaAvalia.toString().substring(6, 10) +
          "/" +
          dataInicioEnvioDadosMatriculaAvalia.toString().substring(3, 5) +
          "/" +
          dataInicioEnvioDadosMatriculaAvalia.toString().substring(0, 2);
      } else {
        dataInicioData = dataInicioEnvioDadosMatriculaAvalia
          .toISOString()
          .slice(0, 10);
      }
      if (typeof dataFimEnvioDadosMatriculaAvalia !== "object") {
        dataFimData =
          dataFimEnvioDadosMatriculaAvalia.toString().substring(6, 10) +
          "/" +
          dataFimEnvioDadosMatriculaAvalia.toString().substring(3, 5) +
          "/" +
          dataFimEnvioDadosMatriculaAvalia.toString().substring(0, 2);
      } else {
        dataFimData = dataFimEnvioDadosMatriculaAvalia
          .toISOString()
          .slice(0, 10);
      }
    }
    // FIM

    // INICIO: Verificar o preenchimento das datas início e fim do envio
    // das notas dos alunos para o sistema avalia.
    if (
      dataInicioEnvioNotaAvalia === "" ||
      dataInicioEnvioNotaAvalia === null
    ) {
      if (dataFimEnvioNotaAvalia !== "" && dataFimEnvioNotaAvalia !== null) {
        mostrarMensagem(
          "error",
          "Data início do envio de dados do avalia não informado",
          "warning",
          6000
        );
        document.getElementById("dataInicioEnvioNotaAvalia").focus();
        return;
      }
    } else {
      if (dataFimEnvioNotaAvalia === "" || dataFimEnvioNotaAvalia === null) {
        mostrarMensagem(
          "error",
          "Data fim do envio de dados do avalia não informado",
          "warning",
          6000
        );
        document.getElementById("dataFimEnvioNotaAvalia").focus();
        return;
      }
      if (dataFimEnvioNotaAvalia < dataInicioEnvioNotaAvalia) {
        mostrarMensagem(
          "error",
          "Data início do envio de dados do avalia maior que a data fim do envio",
          "warning",
          6000
        );
        document.getElementById("dataInicioEnvioNotaAvalia").focus();
        return;
      }

      if (typeof dataInicioEnvioNotaAvalia !== "object") {
        dataInicioNota =
          dataInicioEnvioNotaAvalia.toString().substring(6, 10) +
          "/" +
          dataInicioEnvioNotaAvalia.toString().substring(3, 5) +
          "/" +
          dataInicioEnvioNotaAvalia.toString().substring(0, 2);
      } else {
        dataInicioNota = dataInicioEnvioNotaAvalia.toISOString().slice(0, 10);
      }
      if (typeof dataFimEnvioNotaAvalia !== "object") {
        dataFimNota =
          dataFimEnvioNotaAvalia.toString().substring(6, 10) +
          "/" +
          dataFimEnvioNotaAvalia.toString().substring(3, 5) +
          "/" +
          dataFimEnvioNotaAvalia.toString().substring(0, 2);
      } else {
        dataFimNota = dataFimEnvioNotaAvalia.toISOString().slice(0, 10);
      }
    }
    // FIM

    var parametro = {
      idPerLet,
      dataInicioEnvioNotaAvalia: dataInicioNota,
      dataFimEnvioNotaAvalia: dataFimNota,
      dataInicioEnvioDadosMatriculaAvalia: dataInicioData,
      dataFimEnvioDadosMatriculaAvalia: dataFimData
    };

    dispatch({ type: "INTEGRACAO_SAGAH_ATUALIZAR_DATAS_REQUEST", parametro });
  }
  //onChange={this.alterarDataPesquisa}

  return (
    <div className="box-body">
      <CustomModal
        closeModalFunction={closeModalFunction}
        titleModal="Atualizar Datas"
        actionModalFunction={actionModalFunction}
      >
        <div className="row">
          <div className="col-md-6">
            <i className="fa fa-fw fa-calendar-minus-o" /> Dt Início Envio Dados
            Cadastrais:{" "}
          </div>
          <div className="col-md-6">
            <DataHoraLabel
              id="dataInicioEnvioDadosMatriculaAvalia"
              value={dataInicioEnvioDadosMatriculaAvalia}
              onChange={onChangeDataInicioEnvioDadosMatriculaAvalia}
              withPortal="withPortal"
              showTimeSelect={false}
              formato="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <i className="fa fa-fw fa-calendar-minus-o" /> Dt Fim Envio Dados
            Cadastrais:{" "}
          </div>
          <div className="col-md-6">
            <DataHoraLabel
              id="dataFimEnvioDadosMatriculaAvalia"
              value={dataFimEnvioDadosMatriculaAvalia}
              onChange={onChangeDatasFimEnvioDadosMatriculaAvalia}
              withPortal="withPortal"
              showTimeSelect={false}
              formato="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <i className="fa fa-fw fa-calendar-minus-o" /> Dt Início Envio
            Notas:{" "}
          </div>
          <div className="col-md-6">
            <DataHoraLabel
              id="dataInicioEnvioNotaAvalia"
              value={dataInicioEnvioNotaAvalia}
              onChange={onChangeDataInicioEnvioNotaAvalia}
              withPortal="withPortal"
              showTimeSelect={false}
              formato="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <i className="fa fa-fw fa-calendar-minus-o" /> Dt Fim Envio Notas:{" "}
          </div>
          <div className="col-md-6">
            <DataHoraLabel
              id="dataFimEnvioNotaAvalia"
              value={dataFimEnvioNotaAvalia}
              onChange={onChangeDataFimEnvioNotaAvalia}
              withPortal="withPortal"
              showTimeSelect={false}
              formato="dd/MM/yyyy"
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default DatasControle;
