import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Menu from "../menu/menu";
import MenuComplemento from "../menu/menuComplemento";
import UsuarioInfo from "../../usuario/usuarioInfo";

import UsuarioImagem from "../../usuario/usuarioImagem";

function SideBar() {
  const dispatch = useDispatch();

  //const { name } = useSelector(state => state.auth);

  const name = window.sessionStorage.getItem("@intSaga:name");

  useEffect(() => {
    dispatch({ type: "SET_FORMATAR_NOME_SIDE_BAR", name });
    dispatch({ type: "RECUPERAR_FOTO_SIDE_BAR_REQUEST" });
  }, []);

  const sideBar = useSelector(state => state.sideBar);

  return (
    <aside className="main-sidebar">
      <section className="sidebar">
        <div className="user-panel">
          <UsuarioInfo>
            <p>{sideBar.r_nomeFormatado}</p>
            <a href="#">
              <i className="fa fa-circle text-success" /> Online
            </a>
          </UsuarioInfo>
          <UsuarioImagem>
            <img
              src={sideBar.r_fotoProfessor}
              className="img-circle"
              alt="User Image"
            />
          </UsuarioImagem>
        </div>

        <Menu>
          <MenuComplemento />
        </Menu>
      </section>
    </aside>
  );
}

export default SideBar;
