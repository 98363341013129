import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */
const { Types, Creators } = createActions({
  setTokenPortal: ["tokenPortal"],
  tokenPublicoRequest: null,
  tokenPublicoSuccess: ["tokenPublico", "tokenPortal"],
  signInRequest: ["email", "password"],
  signInSuccess: ["data"],
  signInError: null,
  signOut: null,
  signUpRequest: ["name", "email", "password"],
  getPermissionsSuccess: ["roles", "permissions"]
});

export const AuthTypes = Types;
export default Creators;

/* Initial State */
/*
signedIn: !!window.sessionStorage.getItem("@intSaga:accessToken"),
  tokenPortal: "",
  accessToken: "",
  name: "",
  ide: "",
  role: "",
  pessoa: "",
  idPessoa: 0,
  idUsuario: 0,
  professorFuncao: "",
  usuarioPapelRM: "",
  sub: "",
  codProf: "",
  ra: "",
  chapa: "",
  tokenPublico: window.sessionStorage.getItem("@intSaga:tokenPublico") || null


 */
export const INITIAL_STATE = Immutable({
  loading: false
});

/* Reducers */
export const signInRequestReducer = state =>
  state.merge({
    loading: true
  });
export const signInErrorReducer = state =>
  state.merge({
    loading: false
  });
export const successReducer = (state, { data }) => {
  let pessoaUserInfoResult = data.Result.UserInfoResult.pessoa.split(".");
  let pessoaUsuario = pessoaUserInfoResult[1].split("-");

  let parametros = data.Result.UserInfoResult.usuarioPapelRM.split(".");

  let codProf = "";
  let ra = "";
  let chapa = "";
  for (let i = 0; i < parametros.length; i++) {
    let papelRM = parametros[i].split("-");
    if (papelRM[1] === "Professor") {
      codProf = papelRM[2];
    }
    if (papelRM[1] === "Aluno") {
      ra = papelRM[2];
    }
    if (papelRM[1] === "Funcionario") {
      chapa = papelRM[2];
    }
  }

  window.sessionStorage.setItem(
    "@intSaga:accessToken",
    data.Result.IdentityTokenResult.access_token
  );
  window.sessionStorage.setItem("@intSaga:signedIn", true);
  //window.sessionStorage.setItem("@intSaga:loading", false);
  window.sessionStorage.setItem(
    "@intSaga:name",
    data.Result.IdentityTokenResult.name
  );
  window.sessionStorage.setItem("@intSaga:ide", data.Result.UserInfoResult.ide);
  window.sessionStorage.setItem(
    "@intSaga:role",
    data.Result.UserInfoResult.role
  );
  window.sessionStorage.setItem(
    "@intSaga:pessoa",
    data.Result.UserInfoResult.pessoa
  );
  window.sessionStorage.setItem("@intSaga:idPessoa", pessoaUsuario[0]);
  window.sessionStorage.setItem("@intSaga:idUsuario", pessoaUsuario[1]);
  window.sessionStorage.setItem(
    "@intSaga:professorFuncao",
    data.Result.UserInfoResult.professorFuncao
  );
  window.sessionStorage.setItem(
    "@intSaga:usuarioPapelRM",
    data.Result.UserInfoResult.usuarioPapelRM
  );
  window.sessionStorage.setItem("@intSaga:sub", data.Result.UserInfoResult.sub);
  window.sessionStorage.setItem("@intSaga:codProf", codProf);
  window.sessionStorage.setItem("@intSaga:ra", ra);
  window.sessionStorage.setItem("@intSaga:chapa", chapa);
  return state.merge({
    loading: false
  });
  /*
  return state.merge({
    signedIn: true,
    loading: false,
    accessToken: data.Result.IdentityTokenResult.access_token,
    name: data.Result.IdentityTokenResult.name,
    ide: data.Result.UserInfoResult.ide,
    role: data.Result.UserInfoResult.role,
    pessoa: data.Result.UserInfoResult.pessoa,
    idPessoa: pessoaUsuario[0],
    idUsuario: pessoaUsuario[1],
    professorFuncao: data.Result.UserInfoResult.professorFuncao,
    usuarioPapelRM: data.Result.UserInfoResult.usuarioPapelRM,
    sub: data.Result.UserInfoResult.sub,
    codProf: codProf,
    ra: ra,
    chapa: chapa
  });
  */
};
export const logoutReducer = state => {
  // Pesquisar o clear
  window.sessionStorage.clear();
  return state.merge({
    loading: false
  });

  //window.sessionStorage.setItem("@intSaga:accessToken", null);
  // window.sessionStorage.setItem("@intSaga:signedIn", false);
  //window.sessionStorage.setItem("@intSaga:loading", false);
  /*
  state.merge({
    signedIn: false,
    loading: false,
    token: null
  });
  */
};
export const setTokenPortalReducer = (state, { tokenPortal }) => {
  window.sessionStorage.setItem("@intSaga:tokenPortal", tokenPortal);
  //return state.merge({
  //  tokenPortal
  //});
};

export const tokenPublicoSuccessReducer = (
  state,
  { tokenPublico, tokenPortal }
) => {
  window.sessionStorage.setItem("@intSaga:tokenPortal", tokenPortal);
  window.sessionStorage.setItem("@intSaga:tokenPublico", tokenPublico);
  //window.sessionStorage.setItem("@intSaga:loading", false);
  return state.merge({
    loading: false
  });
  /*
  return state.merge({
    tokenPublico,
    tokenPortal,
    loading: false
  });
  */
};

export const tokenPublicoRequestReducer = state => {
  //window.sessionStorage.setItem("@intSaga:loading", true);
  return state.merge({
    loading: true
  });
};

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_ERROR]: signInErrorReducer,
  [Types.SIGN_IN_REQUEST]: signInRequestReducer,
  [Types.SIGN_IN_SUCCESS]: successReducer,
  [Types.SIGN_OUT]: logoutReducer,
  [Types.SET_TOKEN_PORTAL]: setTokenPortalReducer,
  [Types.TOKEN_PUBLICO_SUCCESS]: tokenPublicoSuccessReducer,
  [Types.TOKEN_PUBLICO_REQUEST]: tokenPublicoRequestReducer
});
