import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8002"
      : "https://publicws.unipam.edu.br"
});

api.interceptors.request.use(config => {
  // const { token } = store.getState().auth;
  // const { active: team } = store.getState().teams;

  const headers = {
    ...config.headers
  };

  //if (token) {
  //  headers.Authorization = `Bearer ${token}`;
  // }

  /*
  if (team) {
    headers.TEAM = team.slug;
  }
  */
  return {
    ...config,
    headers
  };
});

export default api;
