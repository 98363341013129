import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import store from "../../../../store";

/* Types & Action Creators */
const { Types, Creators } = createActions({
  recuperarFotoSideBarSuccess: ["foto"],
  recuperarFotoSideBarRequest: null,
  setFormatarNomeSideBar: ["name"]
});

export const SideBarTypes = Types;
export default Creators;

/* Initial State */
export const INITIAL_STATE = Immutable({
  r_fotoProfessor: "",
  r_nomeFormatado: ""
});

/* Reducers */
export const setFormatarNomeSideBarReducer = (state, { name }) => {
  if (name === "") {
    return state.merge({
      r_nomeFormatado: ""
    });
  } else {
    var nome = name.split(" ");

    return state.merge({
      r_nomeFormatado: nome[0]
    });
  }
};

export const recuperarFotoSideBarSuccessReducer = (state, { foto }) => {
  return state.merge({
    r_fotoProfessor: foto
  });
};

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.RECUPERAR_FOTO_SIDE_BAR_SUCCESS]: recuperarFotoSideBarSuccessReducer,
  //[Types.RECUPERAR_FOTO_SIDE_BAR_REQUEST]: recuperarFotoSideBarRequestReducer,
  [Types.SET_FORMATAR_NOME_SIDE_BAR]: setFormatarNomeSideBarReducer
});
