import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import DatasControle from "../../pages/parametroEnvio/datasControle";

// import { Container } from './styles';

function ModalController() {
  const modal = useSelector(state => state.modal);

  return (
    <div>{modal.modalType === "DatasControle" ? <DatasControle /> : null}</div>
  );
}

export default ModalController;
