import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */
const { Types, Creators } = createActions({
  modalClose: null,
  modalType: ["modalType", "modalData"]
});

export const CursosTypes = Types;
export default Creators;

/* Initial State */
export const INITIAL_STATE = Immutable({
  modalType: "",
  modalData: null
});

/* Reducers */
export const modalCloseReducer = state =>
  state.merge({ modalType: "", modalData: null });

export const modalTypeReducer = (state, { modalType, modalData }) =>
  state.merge({ modalType, modalData });

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.MODAL_CLOSE]: modalCloseReducer,
  [Types.MODAL_TYPE]: modalTypeReducer
});
