import React, { Component, Fragment } from "react";
import ConfigColuns from "../../responsivo/ConfigColuns";
import DatePicker, { registerLocale } from "react-datepicker";

//import "react-datepicker/dist/react-datepicker.css";
import "./dataHoraLabel.css";
import pt from "date-fns/locale/pt";

class DataHoraLabel extends Component {
  constructor(props) {
    super(props);
    registerLocale("pt", pt);
  }

  // Retirado pelo fernando             withPortal
  // Conversar com a Mislene
  render() {
    var formato = "dd/MM/yyyy h:mm aa";
    var showTimeSelect = true;
    if (this.props.formato !== undefined) {
      formato = this.props.formato;
    }
    if (this.props.showTimeSelect !== undefined) {
      showTimeSelect = this.props.showTimeSelect;
    }

    var data = "";
    if (showTimeSelect === false) {
      if (this.props.value !== "") {
        try {
          var parts = this.props.value.split("/");
          data = new Date(parts[2], parts[1] - 1, parts[0]);
        } catch (e) {
          data = this.props.value;
        }
      }
    } else {
      data = this.props.value;
    }

    return (
      <div className="form-group">
        {showTimeSelect === true ? (
          <DatePicker
            className="form-control"
            onChange={this.props.onChange}
            selected={this.props.value}
            dateFormat={formato}
            id={this.props.id}
            timeLabel="Hora:"
            timeFormat="HH:mm"
            withPortal={this.props.withPortal}
            showTimeSelect
          />
        ) : (
          <DatePicker
            className="form-control"
            onChange={this.props.onChange}
            selected={data}
            dateFormat={formato}
            id={this.props.id}
            withPortal={this.props.withPortal}
          />
        )}
      </div>
    );
  }
}
export default DataHoraLabel;
//
//showTimeInput
//calendarClassName="rasta-stripes"
