import "../../../assets/css/dependencies";
import React from "react";

import Header from "../../formulario/cabecalho/header";
import SideBar from "../../menu/sideBar/sideBar";
import Footer from "../../formulario/rodape/footer";

export default props => (
  <div className="wrapper">
    <Header />
    <SideBar />
    <div className="content-wrapper">{props.children}</div>
    <Footer />
  </div>
);
