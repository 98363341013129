import React, { useState, useEffect, Component } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import ContentHeader from "../../components/formulario/cabecalho/contentHeader";
import Content from "../../components/formulario/cabecalho/content";
import Container from "../../components/container/container";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import ToolTipText from "../../components/toolTipText";

import ModalController from "../../components/ModalController";

function ParametroEnvio() {
  const { dataPeriodoLetivo } = useSelector(state => state.integracaoSagah);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "INTEGRACAO_SAGAH_LER_PERIODO_LETIVO_REQUEST" });
  }, []);

  var options = {
    noDataText: "Não existem dados de processamento do envio do log"
  };

  function onClickAlterar({
    idPerLet,
    dataInicioEnvioNotaAvalia,
    dataFimEnvioNotaAvalia,
    dataInicioEnvioDadosMatriculaAvalia,
    dataFimEnvioDadosMatriculaAvalia
  }) {
    const modalData = {
      idPerLet,
      dataInicioEnvioNotaAvalia,
      dataFimEnvioNotaAvalia,
      dataInicioEnvioDadosMatriculaAvalia,
      dataFimEnvioDadosMatriculaAvalia
    };

    dispatch({
      type: "MODAL_TYPE",
      modalType: "DatasControle",
      modalData: modalData
    });
  }
  function cellButton(
    cell,
    {
      idPerLet,
      dataInicioEnvioNotaAvalia,
      dataFimEnvioNotaAvalia,
      dataInicioEnvioDadosMatriculaAvalia,
      dataFimEnvioDadosMatriculaAvalia
    }
  ) {
    return (
      <div className="icones">
        <ToolTipText texto="Alterar Datas">
          <i
            className="fa fa-pencil-square-o btn-pencil"
            data-placement="top"
            onClick={() =>
              onClickAlterar({
                idPerLet,
                dataInicioEnvioNotaAvalia,
                dataFimEnvioNotaAvalia,
                dataInicioEnvioDadosMatriculaAvalia,
                dataFimEnvioDadosMatriculaAvalia
              })
            }
          ></i>
        </ToolTipText>
      </div>
    );
  }

  return (
    <React.Fragment>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <Container>
              <ContentHeader title="Parâmetro para o processamento" />
              <div className="box-body">
                <ModalController />
                <BootstrapTable
                  data={dataPeriodoLetivo}
                  pagination={false}
                  search={false}
                  striped={false}
                  options={options}
                >
                  <TableHeaderColumn
                    dataField="periodoLetivoRM"
                    isKey={true}
                    tdStyle={{ whiteSpace: "normal" }}
                    width="10%"
                  >
                    Período Letivo
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="descricaoTipoCurso"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                  >
                    Tipo de Curso
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="dataInicioEnvioDadosMatriculaAvalia"
                    width="15%"
                    tdStyle={{ whiteSpace: "normal" }}
                  >
                    Dt.Início Dados Cadastro
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="dataFimEnvioDadosMatriculaAvalia"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                  >
                    Dt.Fim Dados Cadastro
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="dataInicioEnvioNotaAvalia"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                  >
                    Dt.Início Notas
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="dataFimEnvioNotaAvalia"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                  >
                    Dt.Fim Notas
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="button"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="5%"
                    dataFormat={cellButton}
                  >
                    Ação
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ParametroEnvio;
