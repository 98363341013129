import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import "../../assets/css/modal.css";

function CustomModal({
  closeModalFunction,
  children,
  titleModal,
  actionModalFunction
}) {
  const showHideClassName = "modal display-block";

  return (
    <div className={showHideClassName}>
      <div>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModalFunction}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id="myModalLabel">
                {titleModal}
              </h4>
            </div>
            <div className="modal-body">
              <section>{children}</section>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                onClick={closeModalFunction}
                data-dismiss="modal"
              >
                Fechar
              </button>
              <div className="right">
                {actionModalFunction !== undefined ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={actionModalFunction}
                  >
                    Atualizar
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomModal;
