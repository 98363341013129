import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

// import { Container } from './styles';

function Main() {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    var urlOriginal = window.location.href;
    var urlSplit = urlOriginal.split("?");
    var parametros = urlSplit[1].split("=");
    var tokenPortal = parametros[1];

    if (window.sessionStorage.getItem("@intSaga:accessToken") === "") {
      dispatch({ type: "TOKEN_PUBLICO_REQUEST", tokenPortal });
    }
  }, []);

  return (
    <div>
      {auth.loading ? (
        <div>
          <h1>Carregando</h1>
        </div>
      ) : (
        <div>
          <h1 />
        </div>
      )}
    </div>
  );
}

export default Main;
