import React from "react";

import ContentHeader from "../../components/formulario/cabecalho/contentHeader";
import Content from "../../components/formulario/cabecalho/content";

function Dashboard() {
  return (
    <div>
      <ContentHeader title="Dashboard" small="" />
      <Content>
        <h1 />
      </Content>
    </div>
  );
}

export default Dashboard;
