import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */
const { Types, Creators } = createActions({
  selectTab: ["tabId"],
  inicializarTab: null,
  showTabs: ["tabIds"],
  showTabsArray: ["tabIds"]
});

export const TabTypes = Types;
export default Creators;

/* Initial State */
export const INITIAL_STATE = Immutable({
  selected: "",
  visible: {}
});

/* Reducers */
export const selectTabReducer = (state, { tabId }) => {
  return state.merge({
    selected: tabId
  });
};

export const inicializarTabReducer = (state, { tabId }) => {
  return state.merge({
    selected: "",
    visible: ""
  });
};

export const showTabsReducer = (state, { tabIds }) => {
  const tabsToShow = {};
  tabIds.forEach(e => (tabsToShow[e] = true));
  return state.merge({
    visible: tabsToShow
  });
};

export const showTabsArrayReducer = (state, { tabIds }) => {
  const tabsToShow = {};
  tabIds[0].forEach(e => (tabsToShow[e] = true));
  return state.merge({
    visible: tabsToShow
  });
};

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SELECT_TAB]: selectTabReducer,
  [Types.INICIALIZAR_TAB]: inicializarTabReducer,
  [Types.SHOW_TABS]: showTabsReducer,
  [Types.SHOW_TABS_ARRAY]: showTabsArrayReducer
});
