import { all, takeLatest } from "redux-saga/effects";

import { recuperarTokenPublico } from "./auth";
import { recuperarFoto } from "../../components/menu/sideBar/sagas/sideBar";
import {
  lerLogEnvioDadosCadastro,
  lerPeriodoLetivo,
  lerLogEnvioDadosNota,
  atualizarData
} from "./integracaoSagah";

import { AuthTypes } from "../ducks/auth";
import { SideBarTypes } from "../../components/menu/sideBar/ducks/sideBar";
import { IntegracaoSagahTypes } from "../ducks/integracaoSagah";

export default function* rootSaga() {
  return yield all([
    takeLatest(AuthTypes.TOKEN_PUBLICO_REQUEST, recuperarTokenPublico),
    takeLatest(SideBarTypes.RECUPERAR_FOTO_SIDE_BAR_REQUEST, recuperarFoto),
    takeLatest(
      IntegracaoSagahTypes.INTEGRACAO_SAGAH_DADOS_CADASTRO_REQUEST,
      lerLogEnvioDadosCadastro
    ),
    takeLatest(
      IntegracaoSagahTypes.INTEGRACAO_SAGAH_DADOS_NOTA_REQUEST,
      lerLogEnvioDadosNota
    ),
    takeLatest(
      IntegracaoSagahTypes.INTEGRACAO_SAGAH_LER_PERIODO_LETIVO_REQUEST,
      lerPeriodoLetivo
    ),
    takeLatest(
      IntegracaoSagahTypes.INTEGRACAO_SAGAH_ATUALIZAR_DATAS_REQUEST,
      atualizarData
    )
  ]);
}
