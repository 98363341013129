//import { toastr } from 'react-redux-toastr'
import Swal from "sweetalert2";
import "./mensagem.css";

export function mostrarMensagem(tipo, mensagem, loader = 0, timeOut) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: timeOut
  });

  Toast.fire({
    type: tipo,
    customClass: tipo,
    confirmButtonColor: "#FFFFFF",
    title: mensagem
  });

  if (loader == 1) Swal.showLoading();

  if (loader != 1) Swal.disableLoading();
}

export function mostrarMensagemComRetornoNoOpen(
  tipo,
  mensagem,
  loader = 0,
  timeOut,
  openDialogo
) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: timeOut
  });

  return Toast.fire({
    type: tipo,
    customClass: tipo,
    confirmButtonColor: "#FFFFFF",
    title: mensagem,
    onOpen: () => {
      Swal.showLoading();
      openDialogo();
    }
  });
}
