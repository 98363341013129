import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { reducer as toastr } from "react-redux-toastr";
import { reducer as auth } from "./auth";
import { reducer as tab } from "../../components/pasta/tab/ducks/tab";
import { reducer as sideBar } from "../../components/menu/sideBar/ducks/sideBar";
import { reducer as integracaoSagah } from "./integracaoSagah";
import { reducer as modal } from "./modal";
export default history =>
  combineReducers({
    toastr,
    auth,
    tab,
    sideBar,
    integracaoSagah,
    modal,
    router: connectRouter(history)
  });
