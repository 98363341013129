import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

if (window.sessionStorage.getItem("@intSaga:accessToken") === null) {
  window.sessionStorage.setItem("@intSaga:tokenPortal", "");
  window.sessionStorage.setItem("@intSaga:accessToken", "");
  window.sessionStorage.setItem("@intSaga:name", "");
  window.sessionStorage.setItem("@intSaga:ide", "");
  window.sessionStorage.setItem("@intSaga:role", "");
  window.sessionStorage.setItem("@intSaga:pessoa", "");
  window.sessionStorage.setItem("@intSaga:idPessoa", "0");
  window.sessionStorage.setItem("@intSaga:idUsuario", "0");
  window.sessionStorage.setItem("@intSaga:professorFuncao", "");
  window.sessionStorage.setItem("@intSaga:usuarioPapelRM", "");
  window.sessionStorage.setItem("@intSaga:sub", "");
  window.sessionStorage.setItem("@intSaga:codProf", "");
  window.sessionStorage.setItem("@intSaga:ra", "");
  window.sessionStorage.setItem("@intSaga:chapa", "");
  window.sessionStorage.setItem("@intSaga:tokenPublico", "");
  window.sessionStorage.setItem("@intSaga:signedIn", false);
}

ReactDOM.render(<App />, document.getElementById("root"));
