import { call, put, select } from "redux-saga/effects";
import { actions as toastrActions } from "react-redux-toastr";
import apiPublicWSComAccessToken from "../../../../services/apiPublicWSComAccessToken";
import SideBarActions from "../ducks/sideBar";
import UserPadrao from "../../../../assets/imagens/user/user.jpg";

export function* recuperarFoto() {
  try {
    //const { codProf } = yield select(state => state.auth);
    const codProf = window.sessionStorage.getItem("@intSaga:codProf");

    //var data = {
    //    CodigoProfessor: codProf)
    //}

    //var parametro = JSON.stringify(data);

    const { data } = yield call(
      apiPublicWSComAccessToken.post,
      "/br.edu.unipam.core.rm.api.comum/api/Professor/RecuperarFoto",
      {
        CodigoProfessor: codProf
      }
    );

    if (data.completed === "success") {
      yield put(
        SideBarActions.recuperarFotoSideBarSuccess(data.messages[0].text)
      );
    } else {
      SideBarActions.recuperarFotoSideBarSuccess(UserPadrao);
    }
  } catch (err) {
    console.log("ERRO: ");
    console.log(err);
  }
}
