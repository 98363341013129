import React from "react";
import logoMini from "../../../assets/imagens/logo/logo-mini.png";
import logo from "../../../assets/imagens/logo/logo.png";

export default props => (
  <header className="main-header">
    <a href="/#/" className="logo">
      <span className="logo-mini">
        <img src={logoMini} />
      </span>
      <span className="logo-lg">
        <img src={logo} />
      </span>
    </a>
    <nav className="navbar navbar-static-top">
      <a href className="sidebar-toggle" data-toggle="offcanvas" />
    </nav>
  </header>
);

//href este campo tem que ser avaliado para colocar o <a
// Foi tirado por enquanto devido ao erro de warning
