import React from "react";
import { Route, Redirect } from "react-router-dom";
import store from "../store";
import TemplateNaoAtenticado from "../components/template/naoAutenticado";

const GuestRoute = ({ component: Component, ...rest }) => (
  <TemplateNaoAtenticado>
    <Route
      {...rest}
      render={props =>
        !!window.sessionStorage.getItem("@intSaga:signedIn") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/app",
              state: {
                from: props.location
              }
            }}
          />
        )
      }
    />
  </TemplateNaoAtenticado>
);

export default GuestRoute;
