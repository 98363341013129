import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import ContentHeader from "../../components/formulario/cabecalho/contentHeader";
import Content from "../../components/formulario/cabecalho/content";
import Container from "../../components/container/container";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

function isExpandableRow(row) {
  if (row.erros.length > 0) {
    return true;
  } else {
    return false;
  }
}

function expandComponent(row) {
  return (
    <BootstrapTable data={row.erros}>
      <TableHeaderColumn
        dataField="cpf"
        isKey={true}
        tdStyle={{ whiteSpace: "normal" }}
        width="12%"
      >
        CPF
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="nome"
        tdStyle={{ whiteSpace: "normal" }}
        width="28%"
      >
        Nome
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="descricaoErro"
        tdStyle={{ whiteSpace: "normal" }}
        width="60%"
      >
        Descrição
      </TableHeaderColumn>
    </BootstrapTable>
  );
}

function IntegracaoEnvioNotas() {
  const { dataNota } = useSelector(state => state.integracaoSagah);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "INTEGRACAO_SAGAH_DADOS_NOTA_REQUEST" });
  }, []);

  var options = {
    noDataText: "Não existem dados de processamento do envio do log"
  };

  return (
    <React.Fragment>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <Container>
              <ContentHeader title="Log Envio de Notas" />
              <div className="box-body">
                <BootstrapTable
                  data={dataNota}
                  pagination={false}
                  search={false}
                  striped={false}
                  options={options}
                  expandableRow={isExpandableRow}
                  expandColumnOptions={{ expandColumnVisible: true }}
                  expandComponent={expandComponent}
                >
                  <TableHeaderColumn
                    dataField="periodoLetivoRM"
                    isKey={true}
                    dataSort={true}
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                  >
                    Período Letivo
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="dataHoraEnvio"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                    dataSort={true}
                  >
                    Data/Hora Envio
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="quantidadeEnviadosCorretos"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                    dataSort={true}
                  >
                    Q.Corretos
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="quantidadeEnviadosComErros"
                    tdStyle={{ whiteSpace: "normal" }}
                    width="15%"
                    dataSort={true}
                  >
                    Q.Errados
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default IntegracaoEnvioNotas;
