import React from "react";
import MenuItem from "./menuItem";
import MenuTree from "./menuTree";

import MenuComplemento from "./menuComplemento";

export default props => (
  <ul className="sidebar-menu">
    <MenuComplemento>{props.children}</MenuComplemento>
    <br />
    <MenuItem path="/app/dashboard" label="Dashboard" icon="fa fa-dashboard" />
    <MenuTree label="Envio" icon="fas fa-angle-double-right">
      <MenuItem
        path="/app/parametroEnvio"
        label="Parâmetros"
        icon="fa fa-file-text-o"
      />
      <MenuItem
        path="/app/integracaoEnvioDadosCadastro"
        label="Dados Cadastro"
        icon="fa fa-check-square"
      />
      <MenuItem
        path="/app/integracaoEnvioNotas"
        label="Notas dos Aluno"
        icon="fa fa-graduation-cap"
      />
    </MenuTree>
    <MenuTree label="Recepção" icon="fas fa-angle-double-left">
      <MenuItem
        path="/app/integracaoRecepcaoNota"
        label="Notas"
        icon="fa fa-check-square"
      />
      <MenuItem
        path="/app/integracaoRecepcaoNotaAluno"
        label="Alunos"
        icon="fa fa-graduation-cap"
      />
    </MenuTree>
  </ul>
);
