import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { BrowserRouter } from "react-router-dom";

import "./config/reactotronconfig";

//import { Wrapper, Container, Content } from "./styles/components";

import store from "./store";
import Routes from "./routes";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes />
        <ReduxToastr />
      </BrowserRouter>{" "}
    </Provider>
  );
}

export default App;
