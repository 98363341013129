import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import store from "../store";

import TemplateAutenticado from "../components/template/autenticado";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <TemplateAutenticado>
    <Route
      {...rest}
      render={props =>
        window.sessionStorage.getItem("@intSaga:signedIn") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/error/1",
              state: {
                from: props.location
              }
            }}
            from="ErroAutenticacao"
          />
        )
      }
    />
  </TemplateAutenticado>
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default PrivateRoute;
